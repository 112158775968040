<template>
	<v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
		<DataTable title="Clients" type="customers" :company="userInfo.company" canDelete address showExpand :headers="$tableHeaders.customers" :items="customers" :itemKey="customerEntry.id" link="/record/customer" />
		<CustomerDialog v-model="dialog" :id="modifiedCustom" />
	</v-col>
</template>

<script>
	import CustomerDialog from '@/components/modules/@customer/CustomerDialog';
	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		props: {
			'id': {
				type: String,
			},
			title: String,
			customers: Array,
			cols: Number,
			sm: Number,
			md: Number,
			lg: Number,
			xl: Number,
		},

		components: {
			DataTable,
			CustomerDialog,
		},
		data() {
			return {
				dialog: false,
				customer: {},
				company: {},


				loader: null,
				loading: false,
				dialogDeleteItem: false,
				identifiant: null,
				expanded: [],
				singleExpand: false,
				search: "",

				viewCustom: false,
				modifiedCustom: "",

				customerEntry: this.$models.user,
				userInfo: this.$models.user
			};
		},

		watch: {
			loader() {
				const l = this.loader;
				this[l] = !this[l];

				setTimeout(() => (this[l] = false), 3000);

				this.loader = null;
			}
		},

		methods: {
			openDeleteDialog(itemtt) {
				this.dialogDeleteItem = true,
					this.identifiant = itemtt;
			},

			deleteCustomer(idCustom) {
				this.dialogDeleteItem = false;
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(idCustom), "Ce client à été supprimé !")
			},

			getColor(status) {
				return this.$cssGlobal.getStatus(status)
			},

			preModifyCustomer(id) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(id).onSnapshot(
					doc => {
						this.customerEntry = doc.data();

						if (this.customerEntry.company) {
							this.$db.collection("companies").doc(this.customerEntry.company).onSnapshot(doc => {
								this.company = doc.data();
							});
						}
					});

				this.modifiedCustom = id;

				this.viewCustom = true;

				this.dialog = true;
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userInfo = doc.data();
			});
		}
	};
</script>