<template>
	<v-flex>
		<v-dialog :value="value" @input="$emit('input')" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Ajouter un Client" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="$emit('input', false)" />
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Profil" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-select v-model="customerEntry.civility"
														:items="this.$listUtils.civility"
														:rules="this.$fieldsRules.required('civilté', true)"
														label="Civilité" required></v-select>
												</v-col>
												<v-col cols="12">
													<v-text-field label="Prénom" hint="Prénom du contact"
														v-model="customerEntry.firstName"
														:rules="this.$fieldsRules.required('prénom')"></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-text-field label="Nom" hint="Nom du contact"
														v-model="customerEntry.lastName"
														:rules="this.$fieldsRules.required('nom')"></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-text-field label="Code Client" hint="Code du contact"
														v-model="customerEntry.nClient"
														:rules="this.$fieldsRules.required('numéro client')">
													</v-text-field>
												</v-col>
												<v-col cols="12">
													<v-menu v-model="menu" :close-on-content-click="false"
														:nudge-right="40" transition="scale-transition" offset-y
														min-width="auto">
														<template v-slot:activator="{ on, attrs }">
															<v-text-field v-model="formatDate" label="Date de naissance"
																prepend-icon="mdi-calendar" readonly v-bind="attrs"
																v-on="on">
															</v-text-field>
														</template>
														<v-date-picker first-day-of-week="1"
															v-model="customerEntry.birthdate" @input="menu = false">
														</v-date-picker>
													</v-menu>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ButtonTrigger title="Contact" :noButton="false" />
									</v-col>
									<v-col cols="12">
										<v-text-field label="N° de Téléphone" hint="N° de Téléphone du contact"
											v-model="customerEntry.phone"></v-text-field>
										<v-text-field label="Adresse Mail" hint="Adresse Mail du contact"
											v-model="customerEntry.email" :rules="this.$fieldsRules.email">
										</v-text-field>
										<v-text-field prefix="https://" label="Site Web"
											hint="Lien du Site Web du Client" v-model="customerEntry.website">
										</v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ButtonTrigger title="Adresse" :noButton="false" />
									</v-col>
									<v-col cols="12">
										<v-text-field label="Adresse" hint="Adresse du contact"
											v-model="customerEntry.address.street"></v-text-field>
										<v-text-field label="Code Postal" hint="Code postal du contact"
											v-model="customerEntry.address.postalCode"></v-text-field>
										<v-text-field label="Région" hint="Région du contact"
											v-model="customerEntry.address.region"></v-text-field>
										<v-text-field label="Ville" hint="Ville du contact"
											v-model="customerEntry.address.city"></v-text-field>
										<v-text-field label="Pays" hint="Pays du contact"
											v-model="customerEntry.address.country"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ButtonTrigger title="A Propos" :noButton="false" />
									</v-col>
									<v-col cols="12">
										<v-textarea v-model="customerEntry.bio" name="input-1-1" label="Description"
											hint="Description du client"></v-textarea>
									</v-col>
									<v-col cols="12">
										<ImageUpload v-if="userInfo.company" :companyID="userInfo.company"
											type="customer" label="Avatar du contact"
											@newdata="customerEntry.avatar=$event"></ImageUpload>
										<v-img height="150" v-if="customerEntry.avatar" :src="$functions.getImgLink(customerEntry.avatar, 200)"
											contain>
										</v-img>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ButtonTrigger title="Complémentaire" :noButton="false" />
									</v-col>
									<v-col cols="12">
										<span class="headline my-5">Mode de communication préféré</span>
										<v-row>
											<v-col cols="4">
												<v-switch v-model="customerEntry.contact.mail" label="Mail"></v-switch>
											</v-col>
											<v-col cols="4">
												<v-switch v-model="customerEntry.contact.call" label="Appel"></v-switch>
											</v-col>
											<v-col cols="4">
												<v-switch v-model="customerEntry.contact.sms" label="SMS"></v-switch>
											</v-col>
											<v-text-field label="Remarque"
												hint="Remarque sur ses modes de communication favoris"
												v-model="customerEntry.contact.preference"></v-text-field>
										</v-row>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ButtonTrigger title="Informations Bancaire" :noButton="false" />
									</v-col>
									<v-col cols="12">
										<v-switch v-model="customerEntry.tva.status" label="Assujétti à la TVA">
										</v-switch>
									</v-col>
									<v-col cols="12">
										<v-text-field label="Numéro TVA" v-if="customerEntry.tva.status"
											hint="Entrer le numéro de TVA Intracommunautaire"
											v-model="customerEntry.tva.number"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-autocomplete :items="this.$listUtils.bankCountries"
											v-model="customerEntry.bank_info.country" label="Code Pays">
										</v-autocomplete>
										<v-text-field label="Banque" hint="Entrer le nom de la banque"
											v-model="customerEntry.bank_info.bank"></v-text-field>
										<v-text-field label="IBAN / BIC" hint="Entrer l'IBAN/BIC"
											v-model="customerEntry.bank_info.iban"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" align="center" justify="center">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<v-btn class="neon evidence mb-4" outlined color="primary"
														@click="dialogs.addCompany = true" v-if="customerEntry.company">
														Lier à une autre entreprise</v-btn>
													<v-btn class="neon evidence mb-4" outlined color="primary"
														@click="dialogs.addCompany = true" v-else>
														Lier à une entreprise</v-btn>
												</v-col>

												<v-col cols="12">
													<InfoCompany :company="companyEntry" v-if="customerEntry.company"
														class="mb-5 mt-2" />
												</v-col>
												<v-col cols="12">
													<v-btn class="neon evidence mb-4" @click="customerEntry.company=''"
														outlined color="warning" v-if="customerEntry.company">Retirer de
														l'entreprise {{ companyEntry.name }}
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" align="center" justify="center">
					<v-card class="bt-success" height="110">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<v-spacer></v-spacer>
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
														class="neon evidence mb-4" width="200" @click="submit()"
														@click.native="$emit('input', false)">
														Ajouter le client
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogs.addCompany" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Lier le Contact" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click="dialogs.addCompany = false" />
				<v-col cols="12">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form" class="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger v-if="!customerEntry.company"
														title="Voulez-vous lier ce contact à une entreprise ?"
														:noButton="false" />
													<ButtonTrigger v-else
														title="Voulez-vous modifier l'entreprise de ce contact ?"
														:noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-autocomplete rounded clearable v-model="company" :items="people"
														chips deletable-chips color="blue-grey lighten-2"
														label="Entreprise" item-text="id" item-value="id">
														<template v-slot:selection="data">
															<v-chip v-bind="data.attrs" :input-value="data.selected"
																@click="data.select">
																<v-avatar left>
																	<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
																</v-avatar>
																{{ data.item.name }}
															</v-chip>
														</template>
														<template v-slot:item="data">
															<v-list-item-avatar>
																<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
															</v-list-item-avatar>
															<v-list-item-content>
																<!-- v-html="data.item.name" -->
																<v-list-item-title>{{ data.item.name }}
																</v-list-item-title>
																<v-list-item-subtitle
																	v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
																</v-list-item-subtitle>
															</v-list-item-content>
														</template>
													</v-autocomplete>
												</v-col>
												<v-col cols="12">
													<v-form ref="form">
														<v-row>
															<v-col cols="12">
																<v-spacer></v-spacer>
																<v-btn :block="$vuetify.breakpoint.xsOnly" outlined
																	color="success"
																	class="neon evidence mb-4 float-right" width="200"
																	@click="dialogs.addCompany = false">
																	Valider
																</v-btn>
															</v-col>
														</v-row>
													</v-form>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</v-flex>
</template>


<script>
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import InfoCompany from '@/components/modules/@company/InfoCompany';
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		props: {
			'value': {
				type: Boolean,
				default: false
			},
			'id': {
				type: String,
				default: undefined
			},
			'customers': {
				type: Array,
				default: Array
			}
		},
		components: {
			InfoCompany,
			ImageUpload,
			ButtonTrigger,
		},
		data() {
			return {
				date: null,
				menu: false,

				viewCustom: false,

				userInfo: this.$models.user,

				companyEntry: this.$models.company,
				customerEntry: this.$models.user,

				company: '',

				dialogs: {
					addCompany: false
				},

				people: []
			};
		},

		watch: {
			id: function () {
				this.preModifyCustomer(this.id);
			},

			customers: function () {
				this.customerEntry.nClient = this.$functions.getNextIndex(this.customers, "nClient");
			},

			company: function () {
				this.customerEntry.company = this.company;

				if (!this.company) return;

				this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(this.company)
					.get().then(doc => {
						this.companyEntry = {
							...this.$models.company,
							...doc.data()
						};
					});
			}
		},

		computed: {
			formatDate() {
				return this.customerEntry.birthdate ? this.$moment(this.customerEntry.birthdate).format('DD/MM/YYYY') : ''
			}
		},

		methods: {
			stateCompany(remove = false) {
				const company = remove ? '' : this.company;

				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(this.id).set({
					'company': company
				}, {
					merge: true
				});

				this.preModifyCustomer(this.id);
			},

			save(date) {
				this.$refs.menu.save(date);
			},

			resetFields() {
				if (this.viewCustom) {
					this.viewCustom = false;

					this.company = '';

					this.customerEntry = {
						civility: "",
						lastName: "",
						firstName: "",

						avatar: "",

						address: {
							street: '',
							city: '',
							region: '',
							country: '',
							postalCode: '',
						},

						phone: "",
						email: "",
						birthdate: "",
						website: "",
						bio: "",

						contact: {
							call: false,
							mail: false,
							sms: false,
							preference: '',
						},

						tva: {
							number: '',
							status: false
						},

						bank_info: {
							bank: '',
							country: [],
							iban: ''
						},

						company: '',
					}
				}
			},

			submit() {
				if (!this.$refs.form.validate()) return;

				var tempDate = this.customerEntry.birthdate ? new Date(this.customerEntry.birthdate) : '';

				const client = {
					...this.customerEntry,
					'belongTo': this.userInfo.company,

					'birthdate': tempDate,
					'creationDate': new Date(),

					'status': 3,
				};

				if (!this.viewCustom) {
					this.$db.collection("companies").doc(this.userInfo.company).collection("customers").add(client).then(
						() => {
							this.$refs.form.resetValidation();

							this.$store.dispatch('setSnackbar', {
								text: `Le client n°${client.nClient}, ${client.firstName} ${client.lastName.toUpperCase()} a été ajouté !`,
							});

							this.resetFields();
						});
				} else {
					this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(this.id).set(
						client, {
							merge: true
						});

					this.$store.dispatch('setSnackbar', {
						text: `Le client n°${client.nClient}, ${client.firstName} ${client.lastName.toUpperCase()} a été modifié !`,
					});

					this.preModifyCustomer(this.id);
				}

				this.$emit('input', false);

				this.viewCustom = true;
				this.resetFields();
			},

			preModifyCustomer(id) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(id).onSnapshot(
					doc => {
						this.customerEntry = doc.data();

						this.company = this.customerEntry.company;

						if (typeof this.customerEntry.birthdate === 'object') this.customerEntry.birthdate = this
							.$moment(this.customerEntry.birthdate.toDate()).format("YYYY-MM-DD");
					});

				this.viewCustom = true;
			}
		},

		created() {
			this.resetFields();

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.$db.collection("companies").doc(doc.data().company).collection("companies").onSnapshot((
					res) => {
					res.forEach((document) => {
						this.people.push({
							'name': document.data().name,
							'content': document.data().nCompany,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "entreprise"
						});
					});
				});
			});

			this.$functions.getNextIndex(this.customers, "nClient");
		}
	};

</script>
